/*Shahood Custom Css*/
.ninjadash-logo img.header-logo{max-width: 170px !important;}
.admin-footer span a{color: #d52c63 !important;}
.admin-footer__links a:hover{color: #d52c63 !important;}
.custom-menu-s li.ant-menu-submenu svg{color: #d52c63 !important;}
.custom-menu-s li.ant-menu-item svg{color: #d52c63 !important;} 
.custom-menu-s .ant-menu-item-active a{color: #d52c63 !important;}

.custom-menu-s li.ant-menu-submenu-active span{color: #d52c63 !important;}
.custom-menu-s li.ant-menu-submenu-active i.ant-menu-submenu-arrow::before, 
.custom-menu-s li.ant-menu-submenu-active i.ant-menu-submenu-arrow::after
{background: #d52c63 !important;}


.custom-menu-s li.ant-menu-submenu-open span{color: #d52c63 !important;}
.custom-menu-s li.ant-menu-submenu-open i.ant-menu-submenu-arrow::before, 
.custom-menu-s li.ant-menu-submenu-open i.ant-menu-submenu-arrow::after
{background: #d52c63 !important;}

.custom-menu-s li.ant-menu-item-selected{background: linear-gradient(to right, #d52c63 22%, #ee364a 78%) !important;}
.custom-menu-s li.ant-menu-item-selected span.ant-menu-title-content a{color: #fff !important;}
.custom-menu-s li.ant-menu-item-selected svg{color: #fff !important;}

/* ul.ant-table-pagination li.ant-pagination-item-active{background: linear-gradient(to right, #A58067 22%, #d52c63 78%) !important;} */
/* .ant-page-header button{background: linear-gradient(to right, #A58067 22%, #d52c63 78%) !important;} */

.payout-modal .ant-modal-body{text-align: center;}
.payout-modal .ant-modal-body .ant-radio-group{margin-bottom: 30px;}
.payout-modal .ant-radio-group span{font-weight:500; color: rgb(64, 64, 64);}

.payout-modal .ant-input:hover{border-color: #d52c63;}
.payout-modal .ant-input:focus{border-color: #d52c63; box-shadow: 0 0 0 2px #ee364a;}


.payout-modal .ant-radio-checked .ant-radio-inner{border-color: #d52c63;}
.payout-modal .ant-radio:hover .ant-radio-inner{border-color: #d52c63 !important;}


.payout-modal .ant-modal-footer button.ant-btn-primary{border-color: #d52c63; background: linear-gradient(to right, #d52c63 22%, #ee364a 78%);}
.payout-modal .ant-modal-footer button.ant-btn-default:hover{border-color: #d52c63;}
.payout-modal .ant-modal-footer button.ant-btn-default:hover span{color: #d52c63;}


.payout-modal button.ant-modal-close{top: -10px; right: -13px; border: 1px solid #d52c63; background: linear-gradient(to right, #d52c63 22%, #ee364a 78%); border-radius: 50px; }
.payout-modal .ant-modal-close-x{width: 30px; height: 30px; font-size: 12px; line-height: 30px;}
.payout-modal .ant-modal-close-x span{color: #fff;}

.category-action-s a.delete svg{height: 16px;}
.attribute-action-s a.delete svg{height: 16px;}
.attribute-options{text-align: start !important;}
.cat-select-s .ant-select-selector{height: 50px !important;}

.product-filter .ant-select .ant-select-selector{height: 39px !important;}
.product-filter .ant-select .ant-select-selector .ant-select-selection-item{line-height: 40px !important;}

.all-product-filter .ant-select .ant-select-selector{height: 39px !important;}
.all-product-filter .ant-select .ant-select-selector .ant-select-selection-item{line-height: 40px !important;}

@media screen and (max-width: 1536px) {
.product-filter input{width: 225px !important;}
.product-filter .ant-select{width: 225px !important;}

.all-product-filter input{width: 100% !important;}
.all-product-filter .ant-select{width: 100% !important;}

}

@media screen and (max-width: 1280px) {
.product-filter {justify-content: space-between !important;}
.product-filter input{width: 100% !important;}
.product-filter .ant-select{width: 100% !important;}
.product-filter button{height: 38px !important; padding: 0px 17.5px !important; font-size: 13px !important;}
.product-filter button.search-s{margin-right: 5px !important;}

.all-product-filter {justify-content: space-between !important;}
/* .all-product-filter input{width: 140px !important;} */
/* .all-product-filter .ant-select{width: 140px !important;} */
.all-product-filter button{height: 38px !important; padding: 0px 17.5px !important; font-size: 13px !important;}
.all-product-filter button.search-s{margin-right: 5px !important;}
}

/**/

/* Ahmed CSS */
.publish-status.active,
.publish-status.blocked{background-color: transparent !important;}
.ant-table-thead > tr > th{background-color: #ffeef0 !important;}
.ant-table-cell a{color: inherit}
.ant-table-cell a:hover{color: #d52c63}
.btn-outline{background-color: transparent !important;color: #d52c63 !important;border-width: 1px !important;margin-right: 10px;}

/* Order Details - Header */
#order-details .details-header{display: flex;flex-wrap: wrap;gap: 15px;;justify-content: space-between;align-items: center;}
#order-details .details-header .col-left > div{display: flex;gap: 37px;}
#order-details .details-header .col-right{display: flex;gap: 11px;}
#order-details .details-header .id{color: #222; font-size: 16px; font-weight: 500;}
#order-details .details-header .time{color: #444;}
#order-details .details-header .status.active{color: #46D42F; background: #E6FFE3; font-size: 12px; }
#order-details .details-header .cancel-btn{ display: inline !important; padding: 4.5px 11.85px; border-radius: 15px; background: #FFF2E9; color: #d52c63; font-size: 12px; font-weight: 500;line-height: 1.8; cursor: pointer;}
#order-details .details-header .ant-select .ant-select-selector{border-radius: 56px !important; background: #F8F8F8;width: 125px;text-align: center;box-shadow: none;}
#order-details .details-header .ant-select .ant-select-selection-item,
#order-details .details-header .ant-select .ant-select-selection-placeholder{height: auto; line-height: 28px !important; font-size: 12px;font-weight: 500;color: #666}
.change-order-status-dropdown.ant-select-dropdown{padding: 10px 0 !important;}
/* Order Details - Info Cards */
#order-details .details-title { font-size: 22px; margin-bottom: 20px; font-weight: 600; display: flex; flex-wrap: wrap; align-items: center; justify-content: space-between; gap: 20px; }
#order-details .view-btn { color: #d52c63; font-size: 14px; font-weight: 500; }
#order-details .change-btn { border-radius: 56px; background: #FFF2E9; color: #d52c63; padding: 5px 15px; font-size: 12px; font-weight: 500; cursor: pointer; }
#order-details .details-info-wrapper:not(:last-child){ margin-bottom: 30px; }
#order-details .details-info-heading{ color: #444; font-size: 14px; font-weight: 400; line-height: normal; margin-bottom: 0; }
#order-details .details-info-wrapper p{ color: #222; font-size: 16px; font-weight: 500; margin-bottom: 0; }
#order-details .details-title + p{ color: #666; font-size: 16px; margin-bottom: 0; }
/* Order Details - Payment Details */
#order-details .pay-details-wrapper{display: flex;gap: 5px;}
#order-details .pay-details-wrapper:not(:last-child){margin-bottom: 20px;}
#order-details .pay-details-wrapper .heading{color: #666; font-size: 16px; font-weight: 400;}
#order-details .pay-details-wrapper .info{color: #222; font-size: 15px; font-weight: 600;}
/* Order Details - Table*/
#order-details .order-table .table-data-view .ant-table-container {padding-bottom: 0;border-bottom: none;}
#order-details .order-table .order-item{display: flex;gap: 16px;align-items: center;}
#order-details .order-table .order-item img{height: 28px;width: 28px;object-fit: cover;object-position: center;}
/* Order Details - Summary */
#order-details .order-summary{width: 300px;margin-left: auto;}
#order-details .order-summary .wrapper{display: flex;flex-wrap: wrap;gap: 10px;justify-content: space-between;margin-bottom: 10px;}
#order-details .order-summary .heading{ color: #666; font-size: 16px; font-weight: 400; }
#order-details .order-summary .info{ color: #222; text-align: right; font-size: 15px; font-weight: 500; }
#order-details .order-summary .total .heading{ color: #333; font-weight: 600; }


/* User Info */
#order-details .customer-info .ant-card-body{padding: 0 !important;}
#order-details .customer-info .details-title{border-bottom: 1px solid #E9E9E9; margin-bottom: 0;justify-content: space-between}
#order-details .customer-info .details-title,
#order-details .customer-info .contact-details{padding: 25px 40px;display: flex;flex-wrap: wrap;}
#order-details .customer-info .contact-details{gap: 70px}
#order-details .customer-info .icon,
#order-details .order-stats .icon{display: inline-flex;;padding: 11px;background-color: #FFF2E9;color:#9B8A7F;font-size: 24px;border-radius: 100%;height: fit-content;}
#order-details .order-stats .icon div{display: inline-flex;}
.enable-switch{display: flex;gap: 10px;align-items: center;}
.enable-switch span{color: #666; font-size: 14px; font-weight: 400; }
.enable-switch .ant-switch{border: 1px solid #CFCFCF  !important;box-shadow: none;height: 25px !important;width: 47px; background-color: rgba(0, 0, 0, 0.25) !important;}
.enable-switch .ant-switch-checked{background-color: #fff !important;}
.enable-switch .ant-switch-handle{top: 5px;left: 5px;}
.enable-switch .ant-switch-checked .ant-switch-handle{left:calc(100% - 14px - 5px)}
.enable-switch .ant-switch-checked .ant-switch-handle::before{background-color: #67D75D;}
#order-details .customer-info .info{ display: flex; align-items: center; gap: 15px; color: #222; font-size: 16px; font-weight: 500; }

#order-details .order-stats {display: flex;flex-wrap: wrap;gap: 70px;margin-top: 50px;}
#order-details .order-stats > div{display: flex;gap: 15px;align-items: center;}
#order-details .order-stats .text > span{display: block;}
#order-details .order-stats .text .heading{color: #444; font-size: 14px; font-weight: 400; }
#order-details .order-stats .text .stats{ color: #222; font-size: 16px; font-weight: 500; }

/* Category View */
.category-details-modal .product-count{ display: flex; gap: 20px; border-radius: 4px; background: #FFF; padding: 20px 22px; width: 293px; margin-bottom: 60px; filter: drop-shadow(0px 5px 11px rgba(0, 0, 0, 0.04)) drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.03)) drop-shadow(0px 45px 27px rgba(0, 0, 0, 0.02)) drop-shadow(0px 81px 32px rgba(0, 0, 0, 0.01)) drop-shadow(0px 126px 35px rgba(0, 0, 0, 0.00)); }
.category-details-modal .product-count .icon{width: 70px; height: 70px; background-color: #FFF2E9; border-radius: 10px; display: grid; place-items: center;}
.category-details-modal .product-count .icon div{display: flex;}
.category-details-modal .product-count .icon svg{ width: 36px; height: 36px; color: #d52c63; }
.category-details-modal .product-count .text > span{ display: block; }
.category-details-modal .product-count .heading{ color: #222; font-size: 14px; }
.category-details-modal .product-count .count{ color: #222; font-size: 34px; font-weight: 600; }

/* .category-details-modal.view-mode {} */
/* .category-details-modal.edit-mode {} */
.category-details-modal.view-mode .ant-form-item-control-input{min-height: fit-content;}
.category-details-modal.view-mode .ant-switch-disabled{opacity: 1;}
.category-details-modal.view-mode .data{color: #222;font-size: 16px;font-weight: 500;}
.category-details-modal.view-mode .attributes {display: flex;gap: 10px;}
.category-details-modal.view-mode .attributes span{background-color: #d52c63; color: #fff;padding: 2px 14px;border-radius: 60px;font-size: 13px;}

.product.details-form.view-mode{}
.product.details-form.edit-mode{}
.product-details-form .ant-form-item .ant-input-disabled,
.product-details-form .ant-form-item .ant-select-disabled .ant-select-selector,
.product-details-form .ant-form-item .ant-input-affix-wrapper-disabled,
.product-details-form .ant-input-number-disabled {background-color: #f5f5f5 !important;border-color: #f5f5f5 !important;color: rgb(64, 64, 64);}

/* Supplier Listing */
#supplier-listing .ant-card { text-align: center; }
#supplier-listing .ant-card h3 { font-size: 18px; font-weight: 600; }
#supplier-listing .ant-card p { margin-bottom: 0; }
#supplier-listing .ant-card p span { font-weight: 500; }
#supplier-listing .ant-image { height: 120px; width: 120px; border-radius: 50%; overflow: hidden; margin-bottom: 10px; }
#supplier-listing .ant-image img{ height: 100%; width: 100%; object-fit: cover; object-position: center; border-radius: 50%; }

/* Supplier Listing */
#supplier-info .ant-card { height: calc(100% - 25px); }
#supplier-info .ant-card-title h3 { margin-bottom: 0; }
#supplier-info .ant-card-head h3 { margin-bottom: 0; }
#supplier-info .ant-card-body h2 { font-size: 18px; font-weight: 600; margin-bottom: 5px; }
#supplier-info .ant-card-body input { margin-bottom: 5px; }
#supplier-info .ant-card-body h3 { font-size: 16px; font-weight: 500; margin-bottom: 0px; }
#supplier-info .ant-card-body p { margin-bottom: 0; }
#supplier-info .ant-card-body p span { font-weight: 500; }
#supplier-info .ant-card-body .config-data { display: flex; align-items: center; justify-content: space-between; margin-bottom: 15px; }
#supplier-info .ant-card-body .config-data button { display: flex; align-items: center; gap: 5px; }

#supplier-info .ant-card-head .ant-card-head-title button { display: flex; align-items: center; gap: 5px; }
#supplier-info .ant-card-head .ant-card-head-title button span{ font-size: 14px; }

#supplier-info .ant-card-body .config-data:last-child { margin-bottom: 0px; }
#supplier-info .ant-card-body .config-data input { margin-bottom: 0px; }
#supplier-info .ant-upload-list { margin-top: 0 !important; }
#supplier-info .ant-upload-list-picture-card-container { margin: 0 !important; }
#supplier-info .ant-upload-list-item-actions a { display: none; }
#supplier-info .avatar-uploader .ant-upload-list-item {border: none; border-radius: 50%; overflow: hidden;}
#supplier-info .ant-image { height: 100px; width: 100px; border-radius: 50%; overflow: hidden; margin-bottom: 0; }
#supplier-info .ant-image img{ height: 100%; width: 100%; object-fit: cover; object-position: center; border-radius: 50%; }
#supplier-info .ant-card-head-title button span {margin: 0;}
#supplier-info .ant-card-head-title button.ant-btn-primary span {color: #fff;}
#supplier-info .avatar-uploader .ant-upload-list-item-actions svg { color: #fff; }
#product-listing .filter-form input { padding: 8px; }
#product-listing .filter-form .ant-select-selector { height: 42px; }

.edit-home3-modal .ant-upload-list-item-actions a { display: none;}
.ant-upload-list .ant-upload-list-item-actions .anticon svg { color: #fff; }


/*Markup Details*/
.supplierWrapper > .ant-card-body{display: flex ; justify-content: space-between; align-items: center; flex-wrap: wrap;}

.supplierWrapper .left{width: 50%; display: flex ; justify-content: flex-start; align-items: center; gap: 40px;}
.supplierWrapper .left .ant-image img{height: 100%; width: 100%; object-fit: cover; object-position: center; border-radius: 50%;}
.supplierWrapper h2{ margin-bottom: 0; font-size: 20px; }

.supplierWrapper .right{width: 50%; text-align: right;}
.supplierWrapper .right p{font-size: 18px; font-weight: 500; margin-bottom: 0;}
.supplierWrapper .right p span{font-weight: 600; font-size: 18px;}

.markupInfo{}
.markupInfo h2{font-size: 22px; font-weight: 600; margin-bottom: 30px;}
.markupInfo p{font-size: 19px; font-weight: 400;}


/*Markup Percentage Button*/
.tableButton-s button{    font-size: 14px; background: rgb(213, 44, 99); border-width: 0px; border-style: solid; color: rgb(255, 255, 255); display: inline-flex ; -webkit-box-align: center; align-items: center; -webkit-box-pack: center; justify-content: center; border-radius: 4px; padding: 0px 20.5px; height: 44px; font-weight: 600; box-shadow: 0px 0px;}


/*Edit Category Modal UI Changes*/
.printingPriceMode{display: flex ; justify-content: flex-start; align-items: flex-start; gap: 10px 20px; flex-wrap: wrap;}
.printingPriceMode h3{width: 100%;}
.printingPriceMode .ant-form-item{width: 48%;}

.printingPriceMode .ant-form-item .ant-form-item-control .ant-select-multiple .ant-select-selector { min-height: 50px; height: auto;}

.printingPriceADD .ant-form-item .ant-form-item-control .ant-select-multiple .ant-select-selector { min-height: 50px; height: auto;}

/*Create Banner Eye Icon Hide*/
.create-banner .ant-upload-list-item:hover .ant-upload-list-item-actions a{display: none !important;}



@media(max-width: 1680px){
  #order-details .customer-info .details-title, #order-details .customer-info .contact-details { padding: 25px; }
  #order-details .customer-info .contact-details,
  #order-details .order-stats{gap: 50px;}
}
@media(max-width: 1536px){
  #order-details .customer-info .contact-details,
  #order-details .order-stats{gap: 15px;}
  #order-details .customer-info .info,
  #order-details .order-stats > div{gap: 10px;}
}
@media(max-width: 1440px){
  #order-details .customer-info .icon,
  #order-details .order-stats .icon{padding: 8px;}
  #order-details .customer-info .icon svg,
  #order-details .order-stats .icon svg{height: 22px;width: 22px;}
  #order-details .customer-info .contact-details, #order-details .order-stats{gap: 12px;}
  #order-details .order-stats{margin-top: 40px;}
  #order-details .customer-info .info,
  #order-details .order-stats > div{gap: 8px;}
}
@media(max-width: 1366px){
  #order-details .details-header .col-left > div{gap: 15px;}
  #order-details .details-title{font-size: 20px;gap: 5px;}
  #order-details .details-info-wrapper:not(:last-child){margin-bottom: 20px;}
  #order-details .pay-details-wrapper:not(:last-child){margin-bottom: 15px;}
  #order-details .order-stats{margin-top: 32px;}
  #order-details .customer-info .contact-details, #order-details .order-stats{gap: 20px;}
  #order-details .customer-info .info, 
  #order-details .order-stats > div{flex: 0 0 40%; min-width: 245px;}

  .supplierWrapper .left {gap: 25px;}
  .supplierWrapper .right p {font-size: 16px;}
  .supplierWrapper .right p span { font-size: 16px; }
}
@media(max-width: 1100px){
}
@media(max-width: 767px){
  #order-details .details-info-wrapper:not(:last-child){margin-bottom: 15px;}
  #order-details .order-summary{margin-bottom: 40px;}
  #order-details .order-stats{margin-top: 0;}

  .supplierWrapper .left { width: 100%; display: block;} 
  .supplierWrapper .left .ant-image{width: 65px !important; height: 80px !important;}
  .supplierWrapper .left .ant-image img{height: 60px !important; object-fit: contain;}
  .supplierWrapper h2 { font-size: 18px; }
  .supplierWrapper .right { width: 100%; text-align: left; }

  .markupInfo p { font-size: 15px;}

}
@media(max-width: 425px){
  #order-details .order-summary{width: 100%;;}
}

/*  */

.ant-table-content::-webkit-scrollbar {
  width: 0.8em !important;
  height: 7px;
  border-radius: 5px;
}
 
.ant-table-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(239, 64, 35, 0.3);
}
 
.ant-table-content::-webkit-scrollbar-thumb {
  outline: none; border-radius: 5px;
  background: linear-gradient(to right, #d52c63 22%, #ee364a 78%) 
}

.ant-table-content button svg{color: #d52c63 !important;}

.table-actions a svg{color: #d52c63 !important;}
.table-actions .ant-select-selection-item{display: flex;align-items: center;}
.custom-table table tbody td {
  padding: 6px 24px !important;
}
.custom-tablecms table tbody td {
  padding: 8px 24px !important;
}
.custom-settings-height{
  min-height: 0px !important;
}

.custom-select-type {
  font-size: 15px !important;
}

.ant-input[disabled], .react-tel-input :disabled,.react-tel-input,
.react-tel-input .form-control  {
  /* color: rgba(0, 0, 0, 0.7) !important;
  background-color: #f5f5f5de !important;
  border-color: #E3E6EF !important;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
  cursor: not-allowed; */
}

.ninjadash-authentication-brand img{max-width: 220px; width: 100%;}
/* .signin-form-s .ant-input:hover,
.signin-form-s .ant-input:focus {border-color: #ef4023; box-shadow: 0 0 0 2px rgba(239, 64, 35, 0.2);}

.signin-form-s .ant-input-password:hover, 
.signin-form-s .ant-input-password:focus{border-color: #ef4023; box-shadow: 0 0 0 2px rgba(239, 64, 35, 0.2);}

.signin-form-s .btn-signin{border-color: #ef4023 !important; background: linear-gradient(to right, #A58067 22%, #d52c63 78%);}
.signin-form-s .ninjadash-auth-extra-links a{color: #ef4023 !important;}
.signin-form-s .ant-checkbox-wrapper:hover .ant-checkbox-inner{border-color: #ef4023 !important;}
.signin-form-s .ant-checkbox-checked .ant-checkbox-inner{background: linear-gradient(to right, #A58067 22%, #d52c63 78%); border-color: #ef4023;}
.signin-form-s .ant-checkbox:hover{border-color: #ef4023 !important;} */


.dropdown-s ul li a:hover{background: linear-gradient(to right, #f04f21bf 0%, #ed1e24e3 94%); color: #fff !important;}
.user-dropdwon .user-dropdwon__links a:hover{color: #d52c63 !important;}

/* Utilitiy classes */

.spin {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}

.d-flex {
  display: flex;
}

.flex-grid .flex-grid-child {
  padding: 0 12px;
}

.align-center-v {
  display: flex;
  align-items: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}
.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.line-height-0 {
  line-height: 0;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.medium-text {
  font-weight: 500;
}

.status {
  display: inline !important;
  font-size: 13px;
  font-weight: 500;
  background-color: #868eae10;
  padding: 4.5px 11.85px;
  border-radius: 15px;
}

.status.Success {
  background-color: #01b81a10;
  color: #01b81a;
}

.status.warning {
  background-color: #fa8b0c10;
  color: #fa8b0c;
}

.status.error {
  background-color: #ff0f0f10;
  color: #ff0f0f;
}

.modal-video-close-btn {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/* spacing classes */

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-0 {
  padding-left: 0 !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.pr-0 {
  padding-right: 0 !important;
}

/* default card style */

.ant-card {
  box-shadow: 0 5px 20px rgba(173, 181, 217, 0.05);
}

.ant-card-body {
  padding: 25px !important;
}

.ant-card-head {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ant-card-head-title span {
  display: inline-block;
  margin-left: 15px;
  font-size: 11px;
  font-weight: 500;
  color: #868eae;
}

.ant-card-head .ant-card-extra {
  display: flex;
  align-items: center;
}

.ant-card-head .ant-card-extra:empty {
  display: none;
}

.ant-card-head .ant-card-extra a {
  color: #868eae;
}

.ant-card-extra .ant-dropdown-trigger {
  line-height: 0;
  order: 1;
  margin-left: 20px;
}

.ninjadash-unresizable {
  resize: none;
}

/* ninjaDash Delete Modal */

.ninjaDash-delete-modal .ant-modal-header {
  text-align: center;
  padding: 22px 24px;
}

.ninjaDash-delete-modal .ant-modal-header .ant-modal-title {
  font-size: 20px;
  color: #ff0f0f;
}

.ninjaDash-delete-modal .ant-modal-body {
  padding: 20px 24px;
}

.ninjaDash-delete-modal .ninjadash-delete-confirm {
  text-align: center;
}

.ninjaDash-delete-modal .ninjadash-delete-confirm__action a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  min-height: 32px;
  border-radius: 3px;
  color: #fff;
  background-color: #01b81a;
}

/* NinjaDash Export Modal */
.ninjadash_export-wrap .ninjadash-button-grp {
  margin: -5px;
}

.ninjadash_export-wrap .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px !important;
}

.ninjadash_export-wrap .ant-select-single:not(.ant-select-customize-input) .ant-select-selection-item {
  line-height: 50px !important;
}

.ninjadash_export-wrap .ninjadash-button-grp button {
  min-height: 44px;
}

.ninjadash_export-wrap .ninjadash-button-grp .ant-btn {
  margin: 5px;
}

/* ant radio group */

.ant-radio-button-wrapper-checked {
  color: #fff;
}

/* chart growth upward */

.growth-downward p,
.growth-upward p {
  font-size: 14px;
  color: #868eae;
  margin: 0;
}

.growth-downward h1 sub svg,
.growth-upward h1 sub svg {
  position: relative;
  top: 2px;
  font-size: 14px;
  font-weight: 600;
  left: 5px;
  bottom: 0;
}

/*
.growth-downward h1, .growth-upward h1 {
  font-size: 22px;
  margin: 6px 0 0;
} */

.growth-downward h1 sub {
  color: #ff0f0f;
}

.growth-upward h1 sub {
  color: #01b81a;
}

/* Chart */

.chart-label {
  display: flex;
}

.chart-label .chart-label__single {
  align-items: center;
}

.chart-label .chart-label__single:not(:last-child) {
  margin-right: 40px;
}

.chart-label .chart-label__single p {
  margin: 0;
  color: #868eae;
}

/* revenue doughnut */

.revenue-doughnut {
  display: flex;
  justify-content: center;
}

.revenue-doughnut > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.revenue-doughnut .rd-labels {
  width: 100%;
  margin-top: 30px;
}

.revenue-chat-percentage span {
  display: block;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 15px;
}

/* progressbars */

.ant-progress {
  display: inline-flex !important;
  align-items: center;
}

.ant-progress > div {
  display: flex;
  flex-direction: column;
}

.ant-progress .ant-progress-outer {
  margin-right: 0 !important;
  padding-right: 0 !important;
}

.revenue-chat-percentage span {
  display: block;
  font-size: 13px;
  color: #868eae;
}

.revenue-chat-percentage span:not(:last-child) {
  margin-bottom: 20px;
}

.ant-progress-status-warning .ant-progress-bg {
  background: #fa8b0c;
}

/* progress bars */

.progress-primary .ant-progress-bg {
  background: #d52c63;
}

.progress-secondary .ant-progress-bg {
  background: #ff69a5;
}

.progress-success .ant-progress-bg {
  background: #01b81a;
}

.progress-info .ant-progress-bg {
  background: #00aaff;
}

.progress-warning .ant-progress-bg {
  background: #fa8b0c;
}

.progress-danger .ant-progress-bg {
  background: #ff0f0f;
}

/* color classes */

.color-primary {
  color: #d52c63;
}

.color-secondary {
  color: #5840ff;
}

.color-info {
  color: #00aaff;
}

.color-warning {
  color: #fa8b0c;
}

.color-success {
  color: #01b81a;
}

.color-danger {
  color: #ff0f0f;
}

.color-dark {
  color: #0a0a0a;
}

.color-error {
  color: #f5222d;
}

.color-gray {
  color: #404040;
}

/* Button Styles */

.button-example .ant-btn {
  margin: 4px;
}

.button-example .ant-btn-group .ant-btn {
  margin: 0;
}

.ant-btn.ant-btn-white {
  color: #404040;
  border-color: #e3e6ef;
}

.ant-btn.ant-btn-white svg {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn.ant-btn-white:hover {
  color: #d52c63;
  background-color: #fff;
  border-color: #d52c63;
}

.ant-btn.ant-btn-white:hover svg {
  color: #d52c63;
}

.ant-btn-white:focus {
  background-color: transparent !important;
}

.ant-btn.ant-btn-dashed {
  border-width: 1px !important;
  color: #090e30;
}

.ant-btn.ant-btn-dashed:hover {
  color: #d52c63;
  border: 1px dashed #d52c63;
}

.ant-btn-primary[disabled] {
  color: #fff;
  background: #d52c63;
}

.ant-btn-light[disabled] {
  background: #fff;
}

.ant-btn-round.ant-btn-sm {
  height: 38px;
}

.ant-btn-white[disabled] {
  background-color: transparent;
  opacity: 0.6;
}

.ant-btn-white[disabled]:hover {
  color: #404040 !important;
}

.ant-btn-primary[disabled]:hover {
  color: #fff !important;
  background: #d52c63 !important;
}

.btn-icon {
  padding: 0 13px;
}

.btn-inc,
.btn-dec {
  height: 38px;
  width: 38px;
  font-size: 20px;
  padding: 0 12px !important;
  border-radius: 10px !important;
  border: 0 none;
}

.btn-inc:hover,
.btn-dec:hover {
  background: #d52c63   !important;
  border: 0 none !important;
}

.btn-inc:hover i,
.btn-inc:hover svg,
.btn-dec:hover i,
.btn-dec:hover svg {
  color: #d52c63;
}

/* input styles */

.ant-form-item-label > label {
  font-weight: 500;
}

.ant-picker-input > input::placeholder {
  color: #adb4d2 !important;
}

.ant-input-affix-wrapper,
.ant-input {
  border-radius: 6px;
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-right: 8px;
}

.ant-input-affix-wrapper-lg .ant-input-lg {
  font-size: 15px;
}

/* Calendar Styles */

.ant-picker-calendar-header .ant-select-selector {
  height: 32px !important;
}

.ant-picker-calendar-header .ant-select-selection-search-input {
  height: 30px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
}

/* pagination */

.ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
}

.ant-pagination-item-active {
  background-color: #d52c63  !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.ant-pagination .ant-pagination-options .ant-select-selection-item {
  font-size: 13px;
  line-height: 30px !important;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper {
  height: 30px;
  line-height: 30px;
}

.ant-pagination .ant-pagination-options .ant-pagination-options-quick-jumper input {
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next,
.ant-pagination .ant-pagination-jump-prev,
.ant-pagination .ant-pagination-jump-next,
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-options .ant-select-selector {
  border: 1px solid #f1f2f6 !important;
  background-color: #fff;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-ellipsis,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-ellipsis {
  color: #404040 !important;
  line-height: 2.6;
}

.ant-pagination .ant-pagination-jump-prev .ant-pagination-item-link,
.ant-pagination .ant-pagination-jump-next .ant-pagination-item-link {
  display: block;
}

.ant-pagination-prev,
.ant-pagination-next {
  line-height: 28px !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 0 none !important;
}

.ant-pagination .ant-pagination-item a {
  color: #404040;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  font-family: 'Jost', sans-serif !important;
}

.ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 30px !important;
  color: #404040;
}

.ant-pagination
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-pagination-item,
.ant-pagination-options,
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-next {
  margin: 4px !important;
}

.ant-table-pagination {
  margin-top: 30px !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table table tr th.ant-table-selection-column,
.ant-table table tr td.ant-table-selection-column {
  padding: 0 16px;
}

/* Wizard Modal */
.submission-successModal {
  text-align: center;
}

.submission-successModal .icon-success {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border: 1px solid #01b81a;
}

.submission-successModal p {
  margin-bottom: 0;
}

.submission-successModal .icon-success svg {
  color: #01b81a;
}

.submission-successModal .submission-action {
  margin: 30px -5px -5px -5px;
}

.submission-successModal .submission-action button {
  margin: 5px;
  height: 38px;
}

/* Select */

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #e3e6ef;
  border-radius: 6px !important;
}

/* Tree Select */

.ant-tree-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 42px !important;
  line-height: 40px;
}

.ant-tree-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
}

.ant-tree-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 40px !important;
}

/* radio style */

.ant-radio-checked .ant-radio-inner {
  border-width: 4px !important;
}

.ant-radio-inner::after {
  content: none !important;
}

/* Statistics */

.ant-statistic .ant-statistic-title {
  color: #9299b8;
}

.ant-statistic .ant-statistic-content span {
  font-size: 20px;
}

/* Steps */

.steps-action.justify-content-center {
  justify-content: center !important;
}

.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role='button']:hover
  .ant-steps-icon
  svg {
  color: #d52c63;
}

/* rate */

.ant-rate-star.ant-rate-star-zero span svg {
  color: #c6d0dc;
}

.ant-rate-star:not(:last-child) {
  margin-right: 2px !important;
}

/* result */

.ant-result-icon {
  margin-bottom: 20px !important;
}

.ant-result-title {
  font-weight: 500;
  margin-bottom: 10px;
}

.ant-result-extra {
  height: 34px;
  padding: 4px 10.72px;
}

.ant-result-content .ant-typography strong {
  font-weight: 500;
}

.ant-result-content .ant-typography:last-child {
  margin-bottom: 0;
}

/* form select */

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  /* line-height: 35px !important; */
  line-height: 45px !important;
}

.ant-select-multiple .ant-select-selector {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.ninjadash-fullwidth-select {
  width: 100%;
}

/* calendar style */

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events .ant-badge-status {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}

.notes-month {
  text-align: center;
  font-size: 28px;
}

.notes-month section {
  font-size: 28px;
}

/* Breadcrumb demo */

.demo {
  margin: 16px;
}

.demo-nav {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8;
}

.demo-nav a {
  line-height: 30px;
  padding: 0 8px;
}

.app-list {
  margin-top: 16px;
}

/* Modal */

.ant-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.ant-modal-body p:last-child {
  margin-bottom: 0;
}

.project-modal {
  padding: 5px !important;
}

.project-modal .projects-members {
  margin-top: -12px;
}

.project-modal .projects-members img {
  margin: 6px;
}

.project-modal .ant-form-item-control-input {
  margin-top: 10px;
  min-height: auto;
}

.project-modal .ant-select-selector {
  border-color: #e3e6ef;
}

.project-modal .ant-picker {
  width: 100%;
}

.project-modal-footer {
  padding: 10px !important;
  text-align: left !important;
}

.ninjadash-addTask-modal {
  max-width: 390px;
}

.ninjadash-addTask-modal .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 0 !important;
}

.ninjadash-addTask-modal .ant-modal-header .ant-modal-title {
  font-size: 16px;
  font-weight: 500;
  color: #0a0a0a;
}

.ninjadash-addTask-modal .ninjadash-addTask-modal-inner .ant-form-item {
  margin-bottom: 15px;
}

.ninjadash-addTask-modal .ant-modal-header .ant-modal-close-x svg {
  color: #9299b8;
}

.ninjadash-addTask-modal .ant-form-item-control-input {
  min-height: 36px;
}

.ninjadash-addTask-modal input::placeholder,
.ninjadash-addTask-modal textarea::placeholder {
  color: #9299b8;
}

.ninjadash-addTask-modal input,
.ninjadash-addTask-modal textarea {
  padding: 6px 20px;
  border-radius: 5px;
}

.ninjadash-addTask-modal textarea {
  resize: none;
  min-height: 125px;
}

.ninjadash-addTask-modal .ninjadash-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin: -6px;
}

.ninjadash-addTask-modal .ninjadash-modal-actions button {
  font-size: 14px;
  margin: 6px;
  height: 38px;
  border-radius: 5px;
}

/* slider */

.ant-slider-handle {
  margin-top: -6px !important;
}

.ant-slider .ant-slider-rail,
.ant-slider-track,
.ant-slider .ant-slider-step {
  height: 3px;
}

/* tags */

.ant-tag {
  font-weight: 600;
  padding: 0 9.5px;
}

/* tabs */

.ant-tabs-tab span {
  display: flex;
  align-items: center;
}

/* popover */

.ant-popover {
  position: fixed !important;
  z-index: 99998 !important;
}

.ant-popover .ant-btn-sm {
  font-size: 13px;
}

.ant-popover .ant-popover-inner {
  padding: 15px;
  box-shadow: 0 15px 25px rgba(146, 153, 184, 0.2);
  border-radius: 6px;
}

.ant-popover-inner .ant-popover-title {
  padding: 5px 10px 10px;
}

.ant-popover-inner .ant-popover-inner-content a {
  color: #404040;
}

/* Drawer */

.ant-drawer {
  z-index: 99999;
}

/* Select Dropdwon */

.ant-select-dropdown {
  padding: 18px 0 !important;
  box-shadow: 0 5px 20px #9299b820 !important;
  border-radius: 0 0 6px 6px !important;
}

.ant-select-item {
  min-height: 20px !important;
  padding: 4px 12px !important;
}

.ant-select-item-group {
  color: #9299b8;
}

.ant-select-item.ant-select-item-option-grouped {
  padding-left: 25px !important;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-active {
  /* background: #d52c63; */
  background: transparent;
  color: #d52c63;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-active .ant-select-item-option-content{

  color: #d52c63;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  /* padding-left: 10px; */
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected {
  color: #d52c63;
  /* background: #d52c63; */
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-selected .ant-select-item-option-content {
  color: #d52c63;
  font-weight: 500;
}

.ant-select-dropdown .ant-select-item .ant-select-item-option-content {
  transition: 0.3s;
  color: #404040;
}

.ant-select-tree-list span.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal:hover,
.ant-select-tree-list span.ant-select-tree-node-content-wrapper.ant-select-tree-node-content-wrapper-normal.ant-select-tree-node-selected:hover{background-color: rgba(112, 79, 56, .3) !important; color: #000;}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected{color: #d52c63; background-color: transparent;}
/* mail props dropdown */

.mail-props {
  padding: 10px 25px;
  border: 0 none;
  background: #fff;
  margin-bottom: 0;
  /* box-shadow: 0 5px 40px rgba(146, 153, 184, 0.25); */
}

.mail-props li {
  display: flex;
  margin-bottom: 12px;
}

.mail-props li span:first-child {
  margin-right: 50px;
  min-width: 40px;
}

.mail-props li span:last-child {
  color: #404040;
}

.mail-props li:last-child {
  margin-bottom: 0;
}

.mail-props li span {
  color: #9299b8;
}

/* Basic Dropdwon */

.ant-dropdown {
  box-shadow: 0 5px 30px #9299b820 !important;
}

.ant-dropdown.wide-dropdwon {
  min-width: 140px !important;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more {
  min-width: 220px !important;
  box-shadow: 0 17px 20px #9299b820;
}

.ant-dropdown.wide-dropdwon.kanbanCard-more a {
  padding: 10px 24px;
}

.ant-dropdown-menu {
  min-width: 200px;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 0;
}

.ant-dropdown-menu li {
  color: #404040;
  padding: 5px 25px;
}

.ant-dropdown-menu li:hover {
  background-color: #d52c63;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__title {
  background: #f4f5f7;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  min-height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ninjadash-top-dropdown {
  width: 340px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav {
  height: 260px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav.notification-list {
  padding: 0 10px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li {
  width: 100%;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a {
  padding: 13px 10px;
  position: relative;
  width: 100%;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li:last-child {
  margin: 0;
}

.ninjadash-top-dropdown a.btn-seeAll {
  position: relative;
  width: calc(100% + 30px);
  left: -15px;
  right: -15px;
  height: calc(100% + 15px);
  bottom: -15px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #d52c63;
  padding: 15px 0;
  border-radius: 0 0 6px 6px;
  justify-content: center;
  background: #fff;
  transition: 0.3s;
}

.ninjadash-top-dropdown a.btn-seeAll:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 -15px 20px #9299b808;
  z-index: 1;
  content: '';
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a.btn-seeAll:hover:after {
  box-shadow: 0 0;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover {
  background: #fff;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover:after {
  opacity: 1;
  visibility: visible;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover figcaption h1 {
  color: #d52c63;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:after {
  position: absolute;
  left: -15px;
  right: -15px;
  top: 0;
  width: calc(100% + 30px);
  height: 100%;
  box-shadow: 0 15px 50px #9299b820;
  z-index: 1;
  content: '';
  opacity: 0;
  visibility: hidden;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content {
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 0;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-icon {
  width: 39.2px;
  height: 32px;
  margin-right: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-icon.bg-primary {
  background: #d52c63;
  color: #d52c63;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-icon.bg-secondary {
  background: #5840ff15;
  color: #5840ff;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-icon svg {
  width: 18px;
  height: 18px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content .notification-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ninjadash-top-dropdown .notification-text h1 {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
  margin-bottom: 4px;
}

.ninjadash-top-dropdown .notification-text h1 span {
  color: #d52c63;
  font-weight: 500;
  padding-left: 0;
}

.ninjadash-top-dropdown .notification-text p {
  font-size: 12px;
  color: #adb4d2;
  margin-bottom: 0;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content img {
  max-width: 40px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption {
  margin: -4px 15px 0;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption .ant-badge-count {
  font-size: 8px;
  min-width: 16px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  border-radius: 8px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption p.ant-scroll-number-only-unit.current {
  height: 15px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption h1 span {
  font-size: 12px;
  font-weight: 400;
}

.ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption p {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.add-file-dropdown a {
  font-size: 14px;
  font-weight: 400;
}

.add-file-dropdown a svg {
  width: 14px;
}

.folder-dropdown {
  min-width: 200px !important;
}

/* Tooltip Styles */

.ant-tooltip .ant-tooltip-inner {
  min-height: 40px;
  padding: 6px 20px;
  font-size: 14px;
  color: #404040;
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  border: 1px solid #f1f2f6;
  box-shadow: 0 8px 15px #9299b815;
}

.ant-tooltip .ant-tooltip-arrow:after {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);
  width: 13px;
  height: 13px;
  background: #fff;
  content: '';
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  width: 14px;
  height: 14px;
  top: 3px;
  background: #fff;
  z-index: 98;
  --antd-arrow-background-color: #fff;
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content:before {
  position: static;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow .ant-tooltip-arrow-content {
  border: 1px solid #e3e6ef;
  box-shadow: 0 0;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  top: -4.071068px;
}

.ant-slider-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow {
  transform: translateY(80%) translateX(-50%);
}

/* Badge Styles */

.ant-badge.badge-success .ant-badge-count {
  background: #01b81a;
}

.badge {
  font-size: 11px;
  font-weight: 500;
  padding: 0 6.5px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.badge .ant-badge-count {
  box-shadow: 0 0;
}

.badge.badge-primary {
  background: #d52c63;
  color: #d52c63;
}

/* Cascade Styles */

.custom-cascade-render .ant-cascader-picker {
  width: 100%;
}

.ant-cascader-picker input::placeholder {
  color: #adb4d2;
}

/* Comment Styles */

.ant-comment-actions li {
  margin-bottom: 0 !important;
}

/* Radio Vertical Button */

.ant-radio-vertical .ant-radio-wrapper {
  display: block;
}

.ant-radio-vertical .ant-radio-wrapper:not(:last-child) {
  margin-bottom: 14px;
}

/* Select Tree */

.ant-select-tree-list .ant-select-tree-node-content-wrapper:hover {
  background-color: #bae7ff !important;
}

.ant-select-tree-list .ant-select-arrow svg {
  font-size: 10px;
}

.ant-tree-select.ant-select-multiple .ant-select-selection-item {
  border: 0 none;
  border-radius: 3px;
  background: #f4f5f7;
  color: #404040;
  font-weight: 500;
}

/* Ant Switch */

.ant-switch:after {
  width: 14px !important;
  height: 14px !important;
}

.ant-switch-small:after {
  width: 10px !important;
  height: 10px !important;
}
.user-switch {display: inline-flex !important;}
.user-switch .ant-switch {background: rgba(0, 0, 0, 0.25) !important;height: auto;}
.user-switch .ant-switch-handle{top: 5px;left: 5px;}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 14px - 5px);
}
.user-switch .ant-switch-checked {background: #d52c63 !important;}
/* Time Picker */

.ant-picker {
  min-width: 250px;
}

/* Input Affix */

.ant-input-affix-wrapper > input.ant-input {
  padding-left: 5px;
}
.ant-input-affix-wrapper > input.ant-input {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.ant-input-affix-wrapper .ant-input-prefix svg {
  color: #e3e6ef;
}

.ant-input-affix-wrapper {
  padding: 8px 20px;
}

/* Space Item */

.ant-space-item .ant-btn span {
  font-size: 14px;
}

/* Pop confirm */

.pop-confirm .ant-btn {
  max-width: 90px;
  padding: 0px 35.5px;
  height: 44px;
}

.pop-confirm-top {
  margin-bottom: 10px;
}

.pop-confirm-bottom {
  margin-top: 10px;
}

.pop-confirm-top .ant-btn:not(:last-child),
.pop-confirm-bottom .ant-btn:not(:last-child) {
  margin-right: 10px;
}

.pop-confirm-left .ant-btn:not(:last-child),
.pop-confirm-right .ant-btn:not(:last-child) {
  margin-bottom: 10px;
}

/* Ant Upload */

.ant-upload .ant-btn {
  font-size: 14px;
  border-radius: 5px;
}

.ant-upload.ant-upload-select-picture-card {
  border-color: #e3e6ef !important;
  border-radius: 5px !important;
  background-color: #f8f9fb !important;
  overflow: hidden;
}

.ant-upload.ant-upload-select-picture-card .anticon {
  margin-bottom: 8px;
}

.ant-upload .anticon svg {
  color: #9299b8;
}

.ant-upload-list {
  margin-top: 10px !important;
}
/* .ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error{border-color: rgba(125, 102, 82, .4);} */
.ant-upload-list-picture-card .ant-upload-list-item,
.ant-upload-list-item .ant-upload-list-item-info {
  padding: 0px !important;
}

.ninjadash-upload-basic .ant-upload.ant-upload-select {
  width: 100%;
  border: 1px solid #e3e6ef;
  border-radius: 4px;
}

.ninjadash-upload-basic .ant-upload.ant-upload-select .ant-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ninjadash-upload-basic .ninjadash-upload-text {
  font-size: 14px;
  color: #9299b8;
  padding-left: 15px;
}

html[dir='rtl'] .ninjadash-upload-basic .ninjadash-upload-text {
  padding-left: 0px;
  padding-right: 15px;
}

.ninjadash-upload-basic .ninjadash-upload-browse {
  font-size: 14px;
  color: #9299b8;
  display: inline-block;
  padding: 14px 23px;
  border-left: 1px solid #e3e6ef;
}

html[dir='rtl'] .ninjadash-upload-basic .ninjadash-upload-browse {
  border-left: 0 none;
  border-right: 1px solid #e3e6ef;
}

.ant-modal-wrap-rtl .ninjadash-event-details-modal .ant-modal-close {
  left: 10px;
}

.ant-card.ninjadash-upload-form .ant-card-body {
  padding-bottom: 15px !important;
}

/* Ant Picker */

.ant-picker {
  border-color: #e3e6ef;
}

/* Ant Dropdown */

.ant-dropdown {
  box-shadow: 0 5px 20px #9299b820;
  background: #fff;
  border-radius: 6px;
  border: 0 none;
}

.ant-dropdown a {
  display: flex;
  align-items: center;
  padding: 8px 24px;
  font-weight: 400;
  color: #404040;
}

.ant-dropdown a i,
.ant-dropdown a svg,
.ant-dropdown a img {
  margin-right: 8px;
  width: 14px;
  height: 14px;
}

.ant-dropdown div {
  box-shadow: 0 0;
  border-radius: 5px;
}

/* Picker Under Input */

.ant-form-item-control-input .ant-picker {
  padding: 0 12px 0 12px;
  min-height: 38px;
  border-color: #e3e6ef;
}

/* Leaflet COntainer */

.leaflet-container {
  z-index: 0;
}

/* Table Bordered*/
.table-bordered .ant-table-thead > tr > th {
  background: #fff;
  border-top: 1px solid #f1f2f6;
}

.table-bordered .ant-table-tbody > tr > td {
  border-color: #f1f2f6 !important;
}

.table-bordered .ant-table-thead tr th,
.table-bordered .ant-table-tbody tr td {
  padding: 16px 25px;
}

.table-bordered .ant-table-thead tr th:last-child,
.table-bordered .ant-table-tbody tr td:last-child {
  text-align: right;
}

/* Table Responsive */

.table-responsive .ant-table-content {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive .ant-table-content .ant-table-cell {
  white-space: nowrap;
}

/* Rich TextEditor  */
.text-editor{
    font-family: Jost, sans-serif !important;
}
.text-editor .ql-toolbar.ql-snow {
  border: 1px solid #cccccc7a;
  font-family: Jost, sans-serif !important;
  border-radius: 5px 5px 0px 0px;
}

.text-editor .ql-container.ql-snow{
  border: 1px solid #cccccc7a;
  font-family: Jost, sans-serif !important;
  border-radius: 0px 0px 5px 5px;
  min-height: 100px;
}

.text-editor .word-count{
  margin-top: 2px;
  text-align: right;
  font-size: 15px;
  position: absolute;
  right: 0px;
  color: #404040;
  white-space: nowrap;
}

.ant-form-item-has-error .text-editor .quill{
  border: 1px solid #FF0F0F !important;
  border-radius: 6px;
  transition: 0.3s all ease-in-out;
}

/* ChatBox Dropdwon */
.atbd-chatbox__messageControl {
  min-width: 210px;
}

.atbd-chatbox__messageControl ul {
  margin: 0;
  padding: 10px 0;
}

.atbd-chatbox__messageControl ul li a {
  padding: 4px 24px;
}

.atbd-chatbox__emoji {
  margin: -4.48px 0;
  padding: 0 10px;
}

.atbd-chatbox__emoji ul {
  display: flex;
  align-items: center;
}

.atbd-chatbox__emoji ul li {
  display: inline-block;
}

.atbd-chatbox__emoji ul li a {
  display: block;
  font-size: 20px;
  padding: 4px 7px;
  background: #fff;
}

.atbd-chatbox__emoji ul li a:hover {
  background-color: transparent;
}

.atbd-chatbox__emoji ul li a svg {
  margin: 0;
}

.rdrMonths {
  flex-wrap: wrap;
}

.ant-space {
  flex-wrap: wrap;
}

.ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  display: flex;
  align-items: center;
}

.ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-item {
  display: inline-flex;
}

.ant-menu-submenu-popup {
  z-index: 105;
}

.ant-menu-submenu-popup .ant-menu-sub {
  padding: 8px 0;
}

.ant-menu-submenu-popup .ant-menu-submenu.ant-menu-submenu-inline .ant-menu-submenu-title,
.ant-menu-submenu-popup .ant-menu-item {
  display: block;
}

.ant-menu-submenu-popup .ant-menu-item {
  margin-bottom: 0 !important;
}

.ant-menu-sub.ant-menu-vertical {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.ant-menu-sub.ant-menu-vertical .ant-menu-item a {
  color: #404040;
}

/* Ninjadash Header More */
.ninjadash-header-more .ninjadash-nav-actions__searchbar {
  display: none;
}

/* page heading */

.ant-page-header.header-boxed {
  padding: 26px 180px 26px 180px !important;
}

.ant-page-header-heading {
  align-items: center;
}

.ant-page-header-heading-title {
  text-transform: capitalize;
}

.ant-page-header-heading-sub-title {
  position: relative;
  top: 2px;
}

/* Swiper Sldier */

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
}

/* Antd drag & drop */

.row-dragging {
  /* background: #fafafa; */
  /* border: 1px solid #ccc; */
  box-shadow: 0 15px 50px #9299b820;
  display: flex;
}

.row-dragging tr {
  box-shadow: 0 15px 50px #9299b820;
}

.row-dragging td {
  padding: 16px;
  color: #000;
  position: relative;
  z-index: 9999;
  opacity: 0.5;
  vertical-align: middle;
}

.row-dragging td .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #01b81a;
  border-color: #01b81a;
}

.row-dragging td .todos-action,
.row-dragging td .table-actions {
  display: none;
}

.row-dragging td .user-info figcaption {
  display: none;
}

.row-dragging td .feather-move,
.row-dragging td .drag_email,
.row-dragging td .drag_company,
.row-dragging td .drag_designation,
.row-dragging td .drag_join-date,
.row-dragging td .active {
  display: inline-block;
  margin-top: 10px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* Ninjadash Event Details Modal */
.ninjadash-event-details-modal .ant-modal-content {
  border-radius: 8px;
}

.ninjadash-event-details-modal .ant-modal-close {
  top: 12px;
  right: 10px;
}

.ninjadash-event-details-modal .ant-modal-close-x svg {
  width: 14px;
  height: 14px;
  color: #ffffff75;
}

.ninjadash-event-details-modal .ant-modal-close-x {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  line-height: 1;
  background-color: transparent;
}

.ninjadash-event-details-modal .ant-modal-close-x:hover {
  background-color: #ffffff15;
}

.ninjadash-event-details-modal .ant-modal-close-x:hover svg {
  color: #ffffff;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-primary .ant-modal-header {
  background-color: #d52c63;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-secondary .ant-modal-header {
  background-color: #5840ff;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-success .ant-modal-header {
  background-color: #01b81a;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-info .ant-modal-header {
  background-color: #00aaff;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-warning .ant-modal-header {
  background-color: #fa8b0c;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-danger .ant-modal-header {
  background-color: #ff0f0f;
}

.ninjadash-event-details-modal.ninjadash-event-details-modal-dark .ant-modal-header {
  background-color: #090e30;
}

.ninjadash-event-details-modal .ant-modal-header {
  padding: 19px 24px;
  border-radius: 8px 8px 0 0;
}

.ninjadash-event-details-modal .ant-modal-header .ant-modal-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

/* File Export Import Modal */
.ninjadash-export-wrap .ant-modal {
  width: 390px !important;
}

.ninjadash-export-wrap .ant-modal .ant-modal-header {
  border-bottom: 0 none;
  padding: 18px 30px 0 30px;
}

.ninjadash-export-wrap .ant-modal .ant-modal-body {
  padding: 25px 30px 30px 30px;
}

.ninjadash-export-wrap .ant-modal .ninjadash-button-grp {
  text-align: right;
}

.ninjadash-export-wrap .ant-modal .ninjadash-button-grp {
  margin: 20px -8px -8px -8px;
}

.ninjadash-export-wrap .ant-modal .ninjadash-button-grp button {
  font-size: 14px;
  font-weight: 500;
  text-align: right;
  height: 40px;
  padding: 0 16px;
  margin: 8px;
}

.ninjadash-export-wrap .ant-modal .ninjadash-button-grp button.ant-btn-white {
  color: #9299b8 !important;
}

.ninjadash-export-wrap .ant-form-item-control-input {
  min-height: 36px;
}

.ninjadash-export-wrap .ant-form-item-control-input input {
  font-size: 14px;
  font-weight: 400;
  padding: 6px 20px;
  border-radius: 5px;
  color: #9299b8;
}

.ninjadash-export-wrap .ant-select-single {
  width: 100% !important;
}

.ninjadash-export-wrap .ant-form .ant-form-item {
  margin-bottom: 15px;
}

.ninjadash-export-wrap .ant-select-single .ant-select-selector {
  padding: 0 20px;
  border-color: #e3e6ef !important;
}

.ninjadash-create-file .ninjadash-button-grp {
  text-align: right;
}

.ninjadash-create-file .ant-modal {
  width: 390px !important;
}

.ninjadash-create-file .ant-modal .ant-form-item {
  margin-bottom: 20px;
}

.ninjadash-create-file .ant-modal-header {
  border-bottom: 0 none;
  padding-bottom: 6px;
}

.ninjadash-create-file .ninjadash-button-grp button {
  height: 40px;
  border-radius: 5px;
  margin: 5px;
}

.ninjadash-create-file .ant-form-item-control-input {
  border-radius: 5px;
}

.ninjadash-create-file .ant-form-item-control-input input {
  border: 1px solid #e3e6ef;
}

.ant-form .ant-select-selection-item,
.ant-form .ant-input-affix-wrapper > input.ant-input{padding-left: 0 !important;}
.ant-form .ant-select-multiple span.ant-select-selection-item{padding-left: 8px !important;}
.ant-form .ant-select-multiple .ant-select-selector{height: 50px;}

/* Task Modal */
.ninjadash-task-details .ant-modal-content .ant-modal-close {
  top: 10px;
}

.ninjadash-task-details .ant-modal-header {
  border-bottom: 0 none;
  padding: 30px 30px 0;
}

.ninjadash-task-details .ant-modal {
  width: 600px !important;
}

.ninjadash-task-details .ant-modal-header .ant-modal-title h4 {
  font-size: 20px;
  font-weight: 500;
  color: #0a0a0a;
  margin-bottom: 4px;
}

.ninjadash-task-details .ant-modal-header .ant-modal-title .sub-text {
  font-size: 14px;
  font-weight: 400;
  color: #868eae;
}

.ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details__label {
  font-size: 16px;
  display: block;
  margin-bottom: 8px;
}

.ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details-modal__description textarea {
  padding: 10px 20px;
  min-height: 88px;
  width: 100%;
  border: 0 none;
  border-radius: 4px;
  background-color: #f4f5f7;
  resize: none;
}

.ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details-modal__description textarea:focus {
  outline: none;
}

.ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details-modal__description textarea::placeholder {
  color: #404040;
  font-size: 15px;
}

.ninjadash-task-details .ant-modal-body {
  padding: 14px 30px 30px 30px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block {
  margin-top: 26px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-wrap {
  position: relative;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form {
  position: absolute;
  width: 240px;
  padding: 18px;
  left: 0;
  top: 50px;
  box-shadow: 0 15px 30px #9299bb30;
  background-color: #fff;
  border: 1px solid #e3e6ef;
  border-radius: 6px;
  z-index: 222;
}

html[dir='rtl'] .ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form {
  left: auto;
  right: 0;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .add-checklist {
  padding: 10px;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 38px;
  border: 1px solid #e3e6ef;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .addChecklist-form-action {
  margin-top: 15px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .addChecklist-form-action a {
  position: relative;
  top: 3px;
  display: inline-flex;
  align-items: center;
  line-height: 1;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block .addChecklist-form .addChecklist-form-action a svg {
  color: #9299bb;
}

.ninjadash-task-details
  .ant-modal-body
  .ninjadash-checklist-block
  .addChecklist-form
  .addChecklist-form-action
  .btn-add {
  margin-right: 15px;
}

html[dir='rtl']
  .ninjadash-task-details
  .ant-modal-body
  .ninjadash-checklist-block
  .addChecklist-form
  .addChecklist-form-action
  .btn-add {
  margin-left: 15px;
  margin-right: 0;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-block button {
  height: 38px;
  padding: 0px 18.37px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row {
  margin-top: 26px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item:not(:last-child) {
  margin-bottom: 30px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item__top button {
  padding: 0px 16.32px;
  color: #525768;
  background-color: #f4f5f7;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item__title {
  font-size: 16px;
  font-weight: 500;
  color: #090e30;
  margin-bottom: 0;
}

.ninjadash-task-details
  .ant-modal-body
  .ninjadash-checklist-row
  .ninjadash-checklist__progress
  .ant-progress-inner
  .ant-progress-bg {
  height: 5px !important;
  background-color: #01b81a;
}

.ninjadash-task-details
  .ant-modal-body
  .ninjadash-checklist-row
  .ninjadash-checklist__progress
  .ant-progress
  .ant-progress-text {
  font-size: 12px;
  font-weight: 500;
  order: -1;
  margin: 0 10px 0 0;
}

html[dir='rtl']
  .ninjadash-task-details
  .ant-modal-body
  .ninjadash-checklist-row
  .ninjadash-checklist__progress
  .ant-progress
  .ant-progress-text {
  margin: 0 0 0 10px;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-tasks {
  margin: 5px 0 5px 0;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-tasks .ant-checkbox-wrapper .ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-color: #c6d0dc;
}

.ninjadash-task-details
  .ant-modal-body
  .ninjadash-checklist-tasks
  .ant-checkbox-wrapper
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: #01b81a;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-tasks .ant-checkbox-wrapper .ant-checkbox-checked:after {
  border-color: #01b81a;
}

.ninjadash-task-details .ant-modal-body .ninjadash-checklist-item .ninjadash-checklist-tasks-wrap button {
  padding: 0px 35px;
  color: #525768;
  background-color: #f4f5f7;
}

.ninjadash-task-details .ant-modal-body {
  max-height: 800px;
  overflow-y: auto;
}

@media (max-width: 767px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px;
  }
}

.page-header-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -6px -4px;
}

.page-header-actions button {
  font-size: 12px;
  font-weight: 500;
  height: 34px;
  padding: 0 12.32px;
  box-shadow: 0 3px 5px #9299b805;
  margin: 6px 4px;
}

.page-header-actions button.ant-btn-white:focus {
  background-color: #fff !important;
}

.page-header-actions button + button {
  margin-left: 8px;
}

.page-header-actions button.ant-btn-white svg {
  width: 12px;
  height: 12px;
  margin-right: 2px;
  color: #d52c63;
}

/* Layout Css */

.ant-menu-dark .ant-menu-item a {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark .ant-menu-item a:hover {
  color: #ffffff;
}

.ant-menu-dark .ant-menu-submenu span {
  color: rgba(255, 255, 255, 0.65) !important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0.05);
}

.ant-menu-inline-collapsed-tooltip a {
  color: #000 !important;
}

.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: transparent;
}

/* Chart Label */

.chart-label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  color: #404040;
}

.chart-label .label-dot {
  margin-right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.chart-label .label-dot.dot-success {
  background: #01b81a;
}

.chart-label .label-dot.dot-info {
  background: #d52c63;
}

.chart-label .label-dot.dot-warning {
  background: #fa8b0c;
}

/* NOtification CSS */

.ant-notification {
  z-index: 99999 !important;
}

.rdrInputRange {
  padding-left: 10px !important;
}

/* Overlay Dark */
.overlay-dark {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #10122130;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
}

.overlay-dark.show {
  z-index: 998;
  opacity: 1;
  visibility: visible;
}

/* Responsive CSS */

@media only screen and (max-width: 1599px) {
  .ant-page-header.header-boxed {
    padding: 26px 130px 26px 130px !important;
  }
}

@media only screen and (max-width: 1500px) {
  .full-width-table .revenue-table {
    min-height: 100%;
  }

  .pop-confirm .ant-btn {
    padding: 0 20px;
    max-width: 60px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 300px !important;
  }

  .pop-confirm.pop-confirm-bottom,
  .pop-confirm.pop-confirm-top {
    margin-left: 80px !important;
  }
}

@media only screen and (max-width: 1399px) {
  .ant-page-header.header-boxed {
    padding: 26px 50px 26px 50px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .ant-page-header {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 991px) {
  .ant-page-header.header-boxed {
    padding: 26px 30px 26px 30px !important;
  }

  .rdrMonths .rdrMonth {
    width: 100%;
    margin-bottom: 30px;
  }

  .rdrDateRangePickerWrapper.PreviewArea {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 20px;
  }

  .ant-menu-inline-collapsed-tooltip {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .ant-page-header-heading-left {
    max-width: 320px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-page-header {
    padding: 26px 15px 26px 15px;
  }

  .ant-page-header-heading {
    flex-flow: column;
    align-items: center;
    justify-content: center !important;
  }

  .ant-page-header-heading-left {
    max-width: 100%;
  }

  .ant-page-header-heading .ant-page-header-heading-title {
    white-space: normal;
    text-align: center;
  }

  .ant-page-header-heading-extra {
    white-space: normal !important;
    margin: 12px 0 4px !important;
  }

  .ant-page-header-heading-extra .page-header-actions {
    white-space: normal !important;
    text-align: center;
  }

  .ant-card-body {
    padding: 20px !important;
  }

  .ant-card-head {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .ant-pagination .ant-pagination-item,
  .ant-pagination .ant-pagination-item.ant-pagination-prev,
  .ant-pagination .ant-pagination-item.ant-pagination-next,
  .ant-pagination .ant-pagination-jump-prev {
    margin-right: 5px;
  }

  .ant-pagination li.ant-pagination-item,
  .ant-pagination li.ant-pagination-prev,
  .ant-pagination li.ant-pagination-next,
  .ant-pagination li.ant-pagination-jump-prev,
  .ant-pagination li.ant-pagination-jump-next {
    font-size: 13px;
    height: 25px;
    min-width: 25px;
    line-height: 22px;
  }

  .ant-pagination li.ant-pagination-prev .anticon,
  .ant-pagination li.ant-pagination-next .anticon {
    vertical-align: 0.15em;
  }

  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    line-height: 2;
    font-family: 'Jost', sans-serif;
  }

  .ant-table-pagination {
    float: none !important;
    text-align: center;
  }

  .ant-table-pagination li.ant-pagination-total-text {
    display: block;
    margin-bottom: 8px;
  }

  .ant-table-pagination li {
    margin-right: 8px !important;
  }

  .ant-pagination .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 25px !important;
  }

  .ant-pagination .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 23px !important;
  }

  .rdrDefinedRangesWrapper {
    border-right: 0 none;
  }

  .project-modal .ant-picker {
    margin-bottom: 30px;
  }

  .project-modal {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .mt-sm-10 {
    margin-top: 10px;
  }

  .ant-page-header-heading-left {
    margin-top: 0;
  }

  .btn-inc,
  .btn-dec {
    height: 30px;
    width: 30px;
  }

  .ant-page-header {
    padding: 26px 20px 16px;
  }

  .ant-layout-header {
    height: auto;
  }

  .ant-card-head {
    line-height: 1;
  }

  .ant-card-head-title {
    white-space: normal !important;
  }

  .pop-confirm .ant-btn {
    max-width: 60px;
    padding: 0 20px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 320px !important;
  }

  .pop-confirm.pop-confirm-bottom {
    margin-left: 95px !important;
  }

  /* Card Heading */
  .ant-card-head-wrapper {
    flex-flow: column;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0 0 16px !important;
    float: none;
    margin: 0;
    flex-flow: column;
  }

  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger + .card-nav {
    margin-bottom: 8px;
  }

  .ant-card-head-wrapper .ant-card-extra .ant-dropdown-trigger {
    margin: 0 !important;
  }

  .card-nav ul li.active:before {
    display: none;
  }

  .ant-card-head-title > div {
    display: flex;
    flex-flow: column;
    align-items: center;
  }

  .ant-card-head-title > div span {
    margin: 8px 0 0;
  }

  .ant-picker-range-wrapper {
    min-width: auto !important;
  }

  .ant-picker-panel-container .ant-picker-panels,
  .ant-picker-datetime-panel {
    flex-direction: column;
  }
}

.bmzxig .ant-table tr th:first-child,
.bmzxig .ant-table tr td:first-child {
  padding-right: 15px;
}

/* Emprt */

.ant-empty .ant-empty-footer button {
  padding: 0px 15.58px !important;
}

/* Add Event Modal */
.addEvent-modal .ant-modal-header {
  padding: 20px 25px;
}

.addEvent-modal .ant-modal-header .ant-modal-close-x svg {
  color: #404040;
}

.addEvent-modal .ant-modal-header .ant-modal-title {
  font-size: 15px;
  font-weight: 500;
  color: #0a0a0a;
}

.addEvent-modal .ant-modal-body {
  padding: 23px 25px;
}

/* Event Dropdown */

.event-dropdown {
  min-width: auto !important;
  max-width: 450px;
  padding: 0 !important;
  margin: 6px 0 0 !important;
  box-shadow: 0 10px 40px #9299b820 !important;
}

.event-dropdown div {
  border-radius: 8px;
}

.event-dropdown .ant-card {
  width: 100% !important;
  margin-bottom: 0 !important;
}

.placement-confirm .ant-btn.ant-btn-white:hover {
  border-color: #d52c63;
}

@media only screen and (max-width: 479px) {
  .placement-confirm {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -10px 0 -5px;
  }

  .placement-confirm .pop-confirm {
    flex: 0 0 50%;
  }

  .pop-confirm .ant-btn {
    display: block;
    padding: 0 20px;
    max-width: 80px;
    min-width: 80px;
    margin: 10px;
  }

  .pop-confirm.pop-confirm-right {
    margin-left: 0px !important;
  }

  .pop-confirm.pop-confirm-top {
    margin-left: 0px !important;
  }

  .pop-confirm.pop-confirm-bottom {
    margin-left: 0px !important;
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .ant-select {
    width: 100% !important;
  }

  .rdrDefinedRangesWrapper {
    width: 100% !important;
  }

  .rdrDateRangePickerWrapper {
    flex-wrap: wrap;
  }

  .ninjadash-top-dropdown {
    width: 280px;
    min-width: 180px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__title {
    min-height: 40px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__nav li:not(:last-child) {
    margin-bottom: 10px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a {
    padding: 10px 0px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__content img {
    margin-right: 15px;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption {
    margin-left: 0;
  }

  .ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption .ninjadash-top-dropdownText {
    min-width: 155px;
  }

  .ant-drawer-content-wrapper {
    width: 260px !important;
  }

  .rdrCalendarWrapper.rdrDateRangeWrapper {
    margin-top: 0;
  }
}

@media only screen and (max-width: 379px) {
  .ant-card-head-wrapper .ant-card-extra .ant-radio-button-wrapper {
    height: 32px !important;
    line-height: 30px !important;
  }

  .ant-notification-notice {
    width: 275px;
  }
}

@media (max-width: 575px) {
  .ant-card-body {
    padding: 15px !important;
  }
}

@media (max-width: 991px) {
  .mail-sideabr {
    box-shadow: 0 0 10px #00000020;
  }

  .ant-card-head-title span {
    margin-left: 10px;
  }
}

/* Invoice Print Style */
@media print {
  .invoice-area .ant-page-header {
    display: none;
  }

  .customizer-trigger {
    display: none;
  }
}

/* .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #fff !important;
} */

/* custom label */

/* card spin loader */

.ant-card-body .sd-spin div,
.ant-card-body .spin div {
  position: relative;
}

.ant-card-body .sd-spin,
.ant-card-body .spin {
  height: 200px;
}

.ant-card-body {
  position: relative;
}

.ant-card-body .sd-spin .ant-spin,
.ant-card-body .spin .ant-spin {
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 200px;
}

.starActive svg {
  color: red;
}

/* vectormap zoom in / zoom out */

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 27px;
  height: 27px;
  background: none;
  color: #404040;
  border: 1px solid #f1f2f6;
  padding: 0;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: auto;
  right: 0;
  top: auto;
  background-color: #fff;
}

.jvectormap-zoomin {
  border-radius: 6px 6px 0 0;
  bottom: 36px;
}

.jvectormap-zoomout {
  border-radius: 0 0 6px 6px;
  bottom: 10px;
}

.jvectormap-tip {
  padding: 7px 12px;
  border: 0 none;
  font-size: 12px;
  background: #0a0a0a;
}

.btn-rtl {
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  background: #4347d9;
  color: #fff;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -30px;
  z-index: 99999999;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* masonry-grid start from here */

.my-masonry-grid {
  display: -webkit-box;
  /* Not needed if autoprefixing */
  display: -ms-flexbox;
  /* Not needed if autoprefixing */
  display: flex;
  margin-left: -10px;
  /* gutter size offset */
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 10px;
  /* gutter size */
  background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

.common-ul {
  padding: 5px 0;
  padding-left: 10px;
}

.display {
  display: none;
}

li.active > .display {
  display: block;
}

/* rtl section from here */
html[dir='rtl'] .ant-card-head-title span {
  margin-right: 15px;
}

html[dir='rtl'] span[aria-label='arrow-right'] svg,
html[dir='rtl'] span[aria-label='arrow-left'] svg {
  transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu.ant-menu-vertical {
  text-align: right !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-placement-rightTop {
  left: -170px !important;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-vertical-left {
  text-align: right;
}

html[dir='rtl'] .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-menu.ant-menu-vertical .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
  transform: rotateY(180deg);
}

html[dir='rtl'] .ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 15px !important;
}

html[dir='rtl'] .project-modal-footer {
  text-align: right !important;
}

html[dir='rtl'] .ant-card-extra .ant-dropdown-trigger {
  margin-right: 20px !important;
  margin-left: 0 !important;
}

html[dir='rtl'] .dark-mode .ninjadash-navbar-menu {
  margin-right: 20px;
}

/* Dark Mode Css */
.dark-mode .RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button span {
  display: flex;
}

.dark-mode .RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button {
  background: #a4a5aa;
}

.dark-mode .ninjadash-top-dropdown a.btn-seeAll,
.dark-mode .ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover,
.dark-mode .ant-picker-panel-container,
.dark-mode .editor-compose .ant-input,
.dark-mode .ant-drawer-content,
.dark-mode .ant-dropdown,
.dark-mode .mail-props,
.dark-mode .ant-popover-inner,
.dark-mode .ninjadash-searchbar .ant-input,
.dark-mode .ant-input-group-addon,
.dark-mode .ant-popover-arrow-content,
.dark-mode .ant-drawer-header,
.dark-mode .ant-popover-arrow-content:before {
  background: #1b1e2b;
}

.dark-mode .ant-btn.ant-btn-white,
.dark-mode .ant-notification-notice,
.dark-mode .ant-pagination-prev .ant-pagination-item-link,
.dark-mode .ant-pagination-next .ant-pagination-item-link,
.dark-mode .ant-pagination .ant-pagination-prev,
.dark-mode .ant-pagination .ant-pagination-next,
.dark-mode .ant-pagination .ant-pagination-jump-prev,
.dark-mode .ant-pagination .ant-pagination-jump-next,
.dark-mode .ant-pagination .ant-pagination-item,
.dark-mode .ant-pagination .ant-pagination-options .ant-select-selector,
.dark-mode .ant-modal .ant-modal-header,
.dark-mode .ant-modal .ant-modal-content,
.dark-mode .ant-modal .ant-modal-content .ant-card,
.dark-mode .ant-input,
.dark-mode .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.dark-mode .ant-form-item-control-input .ant-picker,
.dark-mode .ant-pagination-options-quick-jumper input {
  background-color: #282b37;
}

.dark-mode .ninjadash-task-details .ninjadash-task-details-modal .ninjadash-task-details-modal__description textarea,
.dark-mode .ant-picker-range-arrow::before,
.dark-mode .ninjadash-top-dropdown .ninjadash-top-dropdown__title {
  background-color: #323541;
}

.dark-mode .ant-picker-range-arrow {
  background: linear-gradient(135deg, transparent 40%, #323541 40%);
}

.dark-mode .ant-btn.ant-btn-white,
.dark-mode .ant-btn.ant-btn-dark,
.dark-mode .ant-btn.ant-btn-dashed,
.dark-mode .RichTextEditor__root___2QXK- .ButtonWrap__root___1EO_R button,
.dark-mode .ant-popover-inner .ant-popover-inner-content a,
.dark-mode .pop-confirm .ant-btn,
.dark-mode .ant-pagination-disabled .ant-pagination-item-link,
.dark-mode .ant-pagination-disabled:hover .ant-pagination-item-link,
.dark-mode .ant-pagination .ant-pagination-item a,
.dark-mode .ant-notification-notice-description,
.dark-mode .ant-notification-notice-close-x svg,
.dark-mode .ant-modal-close-x svg,
.dark-mode .ant-drawer-body,
.dark-mode .ant-modal-body,
.dark-mode .ant-modal-confirm-body .ant-modal-confirm-content,
.dark-mode .ant-form-item-control-input .ant-picker,
.dark-mode .ant-select-arrow svg,
.dark-mode .ant-picker-range-separator svg,
.dark-mode .ant-picker-suffix svg,
.dark-mode .ninjadash-searchbar .ant-input,
.dark-mode .ant-picker-cell.ant-picker-cell-in-view,
.dark-mode .ninjadash-task-details .ant-modal-header .ant-modal-title .sub-text,
.dark-mode .ant-popover-message-title {
  color: #a4a5aa;
}

.dark-mode .ant-picker-cell {
  color: #a4a5aa25;
}

.dark-mode .ninjadash-top-dropdown__title,
.ninjadash-task-details .ant-modal-body .ninjadash-checklist-row .ninjadash-checklist-item__title,
.dark-mode .ninjadash-top-dropdown .ninjadash-top-dropdown__content figcaption h1,
.dark-mode .ninjadash-top-dropdown .ninjadash-top-dropdown__nav li a:hover figcaption h1,
.dark-mode .ninjadash-task-details .ant-modal-header .ant-modal-title h4,
.dark-mode .ant-modal-title,
.dark-mode .ant-modal-confirm-body .ant-modal-confirm-title,
.dark-mode .ant-drawer-title,
.dark-mode .ant-form-item-label > label,
.dark-mode .ant-picker-header button,
.dark-mode .ant-notification-notice-message {
  color: #fff;
}

.dark-mode .ant-btn.ant-btn-white:hover {
  color: #d52c63;
}

.dark-mode .ninjadash-task-details .ant-modal-body .ninjadash-checklist-tasks .ant-checkbox-wrapper .ant-checkbox-inner,
.dark-mode .ant-btn.ant-btn-dark,
.dark-mode .ant-btn.ant-btn-white.btn-outlined,
.dark-mode .pop-confirm .ant-btn,
.dark-mode .ant-pagination .ant-pagination-prev,
.dark-mode .ant-pagination .ant-pagination-next,
.dark-mode .ant-pagination .ant-pagination-jump-prev,
.dark-mode .ant-pagination .ant-pagination-jump-next,
.dark-mode .ant-pagination .ant-pagination-item,
.dark-mode .ant-pagination-options-quick-jumper input,
.dark-mode .ant-modal-header,
.dark-mode .ant-modal-footer,
.dark-mode .ant-pagination .ant-pagination-options .ant-select-selector,
.dark-mode .ant-input-group-addon,
.dark-mode .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.dark-mode .ant-form-item-control-input .ant-picker,
.dark-mode .ant-picker-header,
.dark-mode .ant-picker-panel-container .ant-picker-panel,
.dark-mode .ant-drawer-header,
.dark-mode .ant-input {
  border-color: #323541 !important;
}

.dark-mode .ant-pagination .ant-pagination-prev,
.dark-mode .ant-pagination .ant-pagination-next,
.dark-mode .ant-pagination .ant-pagination-jump-prev,
.dark-mode .ant-pagination .ant-pagination-jump-next,
.dark-mode .ant-pagination .ant-pagination-item,
.dark-mode .ant-pagination .ant-pagination-options .ant-select-selector {
  border: 1px solid #282b37 !important;
  background-color: #fff;
}

.dark-mode .ant-btn.ant-raised.ant-btn-white,
.dark-mode .ant-badge-dot,
.dark-mode .ant-badge-count {
  box-shadow: 0 5px 10px #8c90a410;
}

.dark-mode .ant-btn.ant-btn-dashed {
  border-color: #494b55;
}

.dark-mode .ninjadash-navbar-menu {
  margin-left: 20px;
}

/* Top Menu */

.ninjadash-topmenu .ninjadash-blog-details .ninjadash-share-links {
  margin-left: 1000px !important;
}

/* Auth0 style */

.auth0-lock.auth0-lock .auth0-lock-header-logo {
  height: 32px !important;
  margin: 0 0 3px !important;
}

.auth0-lock.auth0-lock .auth0-lock-name {
  font-size: 16px !important;
}

.antd-country-phone-input .ant-select-selector{
  /* border: none !important; */
  /* padding: 0px 14px; */
  /* height: 44px !important; */
}

span.ant-input-affix-wrapper{
  /* padding: 1px 0px !important; */
}
  
.ant-form-item-control-input-content .PhoneInput{
  color: rgb(64, 64, 64);
  background-color: rgb(255, 255, 255);
  border-color: rgb(227, 230, 239);
  box-sizing: border-box;
  /* margin: 0; */
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  width: 100%;
  min-width: 0;
  padding: 12px 15px;
  color: #666D92;
  font-size: 15px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #E3E6EF;
  border-radius: 4px;
  transition: all 0.3s;
}

.ant-form-item-control-input-content .PhoneInputInput,
.ant-form-item-control-input-content .PhoneInputInput:focus, 
.ant-form-item-control-input-content .PhoneInputInput:active{ border: none; margin-left: 5px; }
.ant-form-item-control-input-content .react-tel-input { font-family: unset !important; }
.ant-form-item-control-input-content .react-tel-input .special-label{ display: none; }
.ant-form-item-has-error .react-tel-input .form-control,
.ant-form-item-has-error .react-tel-input .form-control:focus{ border: 1px solid #FF0F0F !important; border-radius: 4px !important; transition: all 0.3s !important; box-shadow: 0 0 0 2px rgba(255, 15, 15, 0.2); }
.ant-form .ant-form-item .ant-form-item-control-input .ant-picker { width: 100%; padding: 10.7px 8px !important; }
.ant-picker-status-error.ant-picker { border-color: #FF0F0F !important; }
.ant-form .ant-form-item .ant-input-number{width: 100%;}
.ant-form .ant-form-item .ant-input-number-lg input{ width: 100%; height: 50px; }

.react-tel-input .form-control, .react-tel-input .form-control:focus { color: rgb(64, 64, 64) !important; background-color: rgb(255, 255, 255) !important; border-color: rgb(227, 230, 239) !important; box-shadow: 0 0 0 1px #ffffff !important; }
.react-tel-input .country-list .country { padding: 6px 9px 6px 46px !important; }
.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight { background-color: rgb(242, 234, 251) !important; }



/* Dashboard - Icons Row 1 */
#dashboard .ninjadash-overview-card__top--icon{ top: 0; right: 0; transform: translateX(40%); width: 40%; max-width: 150px; height: 100%; border-radius: 100%; padding: 30px; }
#dashboard .ant-card.ninjadash-overview-halfCircle-card .ant-card-body {padding: 25px !important;}
#dashboard .ninjadahs-overview-label {font-size: 16px;margin-bottom: 0;}
#dashboard .ninjadash-overview-total {font-size: 25px; margin-bottom: 0; }
#dashboard .ant-card .ninjadash-overview-card svg { height: 25px; width: 25px; }
#dashboard .card-mesh-wrap {background: transparent;border-radius: 0;margin-bottom: 0;gap: 25px 10px;flex-wrap: wrap;}
#dashboard .card-mesh-wrap .ninjadash-overview-card-single { flex: 0 0 calc(25% - 19px); }
#dashboard .card-mesh-wrap .ninjadash-overview-card-single .ant-card {border-radius: 10px;}
#dashboard .ninjadash-overview-card-single::after{all:unset;}

/* #dashboard .ant-card-body .ninjadash-overview-card .ninjadash-overview-card__top .ninjadash-overview-card__top--icon.ninjadash-info { background-color: #FFF7F2; } */
/* #dashboard .ant-card-body .ninjadash-overview-card .ninjadash-overview-card__top .ninjadash-overview-card__top--icon.ninjadash-info svg{stroke: #d52c63;}  */
/* #dashboard .ant-card-body .ninjadash-overview-card .ninjadash-overview-card__top .ninjadash-overview-card__top--icon.ninjadash-info svg path,  */
/* #dashboard .ant-card-body .ninjadash-overview-card .ninjadash-overview-card__top .ninjadash-overview-card__top--icon.ninjadash-info i{ fill: #d52c63; } */
/* #dashboard .ninjadash-overview-card .ninjadash-overview-card__right .ninjadash-overview-card__right--content .ninjadash-overview-total{font-size: 25px;} */
/* #dashboard .ninjadash-overview-card .ninjadash-overview-card__right .ninjadash-overview-card__right--content .ninjadahs-overview-label{margin-top: auto;} */


/* Dashboard - Icons Row 2 */
#dashboard .ninjadash-overview-card-box{height: calc(100% - 25px);}
#dashboard .ant-card.ninjadash-overview-halfCircle-card{height: 100%;}
#dashboard .ninjadash-overview-card .order-details-wrapper{display: flex; gap: 20px;}
#dashboard .ninjadash-overview-card .ninjadash-overview-card__right .ninjadash-overview-card__right--content { display: flex; flex-direction: column; align-items: start; justify-content: center; }

#dashboard .ant-page-header.ant-page-header-ghost{padding-left: 0;}

@media (max-width: 1536px){
  #dashboard .card-mesh-wrap .ninjadash-overview-card .ninjadash-overview-card__left .ninjadash-overview-card__left--icon{width: 60px;height: 60px;}
}
  @media (max-width: 1440px){
  #dashboard .card-mesh-wrap .ninjadash-overview-card-single { flex: 0 0 calc(50% - 13px); }
  #dashboard .card-mesh-wrap .ninjadash-overview-card-single .ant-card .ant-card-body {padding: 20px;}
}
@media (max-width: 1366px){
  #dashboard .ant-card.ninjadash-overview-halfCircle-card .ant-card-body,
  #dashboard .card-mesh-wrap .ninjadash-overview-card-single .ant-card .ant-card-body {padding: 20px !important;}
  #dashboard .ninjadash-overview-total {font-size: 22px;font-weight: 500;}
}
@media (max-width: 767px){
  #dashboard .ninjadahs-overview-label {font-size: 15px;}
}
@media (max-width: 600px){
  #dashboard .ninjadash-overview-card__top--icon{padding: 25px;}
  #dashboard .ant-card .ninjadash-overview-card svg{height: 20px;width: 20px;}
  #dashboard .card-mesh-wrap .ninjadash-overview-card .ninjadash-overview-card__left .ninjadash-overview-card__left--icon{height: 50px;width: 50px;}
}
@media (max-width: 425px){
  #dashboard .ninjadash-overview-card__top--icon{width: 30%;transform: translateX(30%);}
  #dashboard .card-mesh-wrap .ninjadash-overview-card-single { flex: 0 0 100%; }
  #dashboard .ninjadash-overview-card__top--icon{padding: 30px;}
}
@media (max-width: 320px){
  #dashboard .ninjadash-overview-card__top--icon{width: 40%;transform: translateX(40%);}
}

/* Order Statuses */
.status-item-utils {
  display: inline !important;
  font-size: 13px;
  font-weight: 500;
  background-color: #868eae10;
  padding: 4.5px 11.85px;
  border-radius: 15px;
}

.status-item-utils.pending {
  color: rgb(251 135 0);
  background: rgb(255 225 196);
}

.status-item-utils.in-progress {
  color: rgba(250, 139, 12);
  background: rgb(255 245 235);
}

.status-item-utils.shipped {
    color: #46D42F;
    background: #E6FFE3;
}

.status-item-utils.delivered {
  color: rgb(1, 184, 26);
  background: rgb(234 249 236);
}

.status-item-utils.cancelled {
  color: rgb(255, 15, 15);
  background: rgba(255, 15, 15, 0.063);
}

/* Edit Profile - Edit Shippng Address*/
.edit-profile-diseable,
.lat-lng-diseabled {
  background-color: #f5f5f5 !important;
  border-color: #e3e6ef !important;
  box-shadow: none;
  color: rgba(0, 0, 0, .25) !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
}


.long_text_overflow_dotted{
  overflow: hidden;
  max-width: 250px;
  text-overflow: ellipsis;
  padding: 8px 0px;
}

/* Manage Home Tabs */
.manage-home-tabs .ant-tabs .ninjadash-page-header-main {
  background-color: #ffffff !important;
  padding-right: 0px !important;
}

.ant-form-item-has-error .text-editor, 
.ant-form-item-has-error .text-editor:hover {
  background: #fff !important;
  border-color: #FF0F0F !important;
}

/* Remove Validation Border Color */
.ant-form.no-validation .ant-form-item.ant-form-item-has-success .ant-input {
  border-color: rgb(227, 230, 239);
}

.enable-switch {border: 1px solid #CFCFCF  !important;box-shadow: none;height: 25px !important;width: 47px; background-color: rgba(0, 0, 0, 0.25) !important;}
.enable-switch.ant-switch-checked{background-color: #fff !important;}
.enable-switch .ant-switch-handle{top: 5px;left: 5px;}
.enable-switch.ant-switch-checked .ant-switch-handle{left:calc(100% - 14px - 5px)}
.enable-switch.ant-switch-checked .ant-switch-handle::before{background-color: #67D75D;}